<template>
  <div class="buyer">
    <div class="mainbody">
      <div class="header-title"><p>买家入驻-基本信息</p></div>
      <div class="header-text">
        <p>
          因税票交易的特殊性，买家必须为企业，请提供企业相关的营业执照等信息进行入驻申请。
        </p>
      </div>
      <div class="basic-information">
        <div class="enterprise-left">
          <div class="enterprise-information">
            <div class="information-title">
              <span>企业信息</span>
            </div>
            <p>
              <span style="color: red">*</span>主体名称:&nbsp;
              <a-input
                class="buyer-input"
                v-model="enterprise.commercialName"
                placeholder="主体名称"
                :class="enterprise.commercialName != '' ? 'activate-input' : ''"
              />
            </p>
            <p>
              统一信用代码:&nbsp;
              <a-input
                class="buyer-input"
                v-model="enterprise.uniformCode"
                placeholder="统一信用代码"
                :class="enterprise.uniformCode != '' ? 'activate-input' : ''"
              />
            </p>
            <p>
              <span style="color: red">*</span>法人姓名:&nbsp;
              <a-input
                class="buyer-input"
                v-model="enterprise.legalName"
                placeholder="法人姓名"
                :maxLength="15"
                :class="enterprise.legalName != '' ? 'activate-input' : ''"
              />
            </p>
            <p>
              <span style="color: red">*</span>签署邮箱:&nbsp;
              <a-input
                class="buyer-input"
                v-model="enterprise.signerEmail"
                placeholder="用于接收与管理电子合同的邮箱"
                :class="enterprise.signerEmail != '' ? 'activate-input' : ''"
              />
            </p>
            <!-- <p>
              归属集团简称:&nbsp;
              <a-input
                class="buyer-input"
                v-model="enterprise.abbreviation"
                placeholder="归属集团简称"
                :class="enterprise.abbreviation != '' ? 'activate-input' : ''"
                disabled
              />
            </p> -->
          </div>
          <div class="contract-information">
            <div class="information-title">
              <span>合同签署人信息</span>
            </div>
            <p>
              <span style="color: red">*</span>姓名:&nbsp;
              <a-input
                class="buyer-input"
                v-model="signer.signerName"
                @input="signer.signerName=$event.target.value.replace(/[^\u4E00-\u9FA5]/g,'')"
                placeholder="签署人姓名"
                :maxLength="15"
                :class="signer.signerName != '' ? 'activate-input' : ''"
              />
            </p>
            <p>
              <span style="color: red">*</span>身份证号:&nbsp;
              <a-input
                class="buyer-input"
                :maxLength="18"
                @input="signer.signerIdCard=$event.target.value.replace(/^\s+|\s+$/g,'')"
                v-model="signer.signerIdCard"
                placeholder="签署人身份证号"
                :class="signer.signerIdCard != '' ? 'activate-input' : ''"
              />
            </p>
            <p>
              <span style="color: red">*</span>手机号:&nbsp;
              <a-input
                class="buyer-input"
                v-model="signer.signerMobile"
                :maxLength="11"
               @input="signer.signerMobile=$event.target.value.replace(/[^\d]/g,'')"
                placeholder="签署人手机号"
                :class="signer.signerMobile != '' ? 'activate-input' : ''"
              />
            </p>
          </div>
        </div>
        <div class="enterprise-right">
          <a-spin  tip="识别中..." :spinning="spinning">
          <div class="permit-logo" @click="uploadocr">
            <div class="ocr">
              <input
                v-show="false"
                id="addImage"
                ref="addImage"
                alt=""
                class="upload"
                type="file"
                name="input"
                multiple="multiple"
                accept="image/png,image/jpeg,image/gif,image/jpg"
                @change="getImgFile"
              />
              <p><img src="../../assets/images/u161.png" alt="" /></p>
              <p class="ocr-text">请上传企业营业执照*</p>
            </div>
            <div class="ocrimg" v-show="fileName">
              <img :src="fileName" alt="" />
            </div>
          </div>
          </a-spin>    
        </div>
      </div>
      <div class="xy">
        <a-button class="next" @click="next" :loading="loading"> 下一步</a-button>
      </div>
    </div>
  </div>
</template>
<script>
import { ocr, apply } from '../../api/buyer'
import { uploadPhoto } from '../../api/utils'
var myreg =/^1[3-9][0-9]{9}$/
const regEmail = /.{1,}@.{1,}\..{1,}/
const idCard=/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
import cookie from '@/utils/js-cookie.js'

export default {
  components: {},
  data() {
    return {
      enterprise: {
        commercialName: '', //商业名称|主体名称
        legalName: '', // 法人姓名
        address: '', //经营地址
        uniformCode: '', //统一信用代码
        signerEmail: '', //签署人邮箱
        abbreviation: '', //集团
        busiLicenseUrl: '', //营业执照文件地址
        licenseRegTime: '' //营业执照过期时间
      },
      signer: {
        signerName: '', //签署人姓名
        signerIdCard: '', //签署人身份证号
        signerMobile: '' //签署人手机号
      },
      fileName: '', //营业执照
      error: {
        commercialName: '请输入主体名称',
        uniformCode: '请输入统一信用代码',
        signerName: '请输入签署人姓名',
        signerIdCard: '请输入正确的签署人身份证号',
        signerMobile: '请输入正确的签署人手机号',
        fileName: '请上传企业营业执照',
        legalName: '请输入法人姓名',
        signerEmail: '请输入正确的签署邮箱'
      },
      loading:false,
      spinning:false
    }
  },

  methods: {
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },
    getImgFile(e) {
      if (e.target.files[0]) {
      this.uploadImage(e.target.files)
      e.target.value = null
      }
    },
    // 选择图片
    uploadocr() {
      this.$refs.addImage.dispatchEvent(new MouseEvent('click'))
    },
    // 上传图片
    async uploadImage(images) {
      const file = images[0]
      uploadPhoto(file, (info) => {
        this.spinning=true
        ocr({ url: info }).then(async (res) => {
            this.spinning=false
          if (res.code === 200) {
            this.fileName = await info
            this.enterprise.uniformCode = res.data.uniformCode
            this.enterprise.commercialName = res.data.commercialName
            this.enterprise.legalName = res.data.legalName
            this.enterprise.address = res.data.address
            this.enterprise.licenseRegTime = res.data.licenseRegTime
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    // 点击提交
    next() {
      let verify = false
      if (!this.fileName) {
        this.$message.error(this.error.fileName)
      } else if (!this.enterprise.commercialName) {
        this.$message.error(this.error.commercialName)
      } else if (!this.enterprise.uniformCode) {
        this.$message.error(this.error.uniformCode)
      } else if (!this.enterprise.legalName) {
        this.$message.error(this.error.legalName)
      } else if (!regEmail.test(this.enterprise.signerEmail)) {
        this.$message.error(this.error.signerEmail)
      } else if (!this.signer.signerName) {
        this.$message.error(this.error.signerName)
      } else if (!idCard.test(this.signer.signerIdCard)) {
        this.$message.error(this.error.signerIdCard)
      } else if (!myreg.test(this.signer.signerMobile)) {
        this.$message.error(this.error.signerMobile)
      } else {
        verify = true
      this.loading=true
      }
      if (verify)    
        return apply({
          legalName: this.enterprise.legalName,
          address: this.enterprise.address,
          commercialName: this.enterprise.commercialName,
          uniformCode: this.enterprise.uniformCode,
          busiLicenseUrl: this.fileName,
          email: this.enterprise.signerEmail,
          licenseRegTime: this.enterprise.licenseRegTime,
          signerName: this.signer.signerName,
          signerIdCard: this.signer.signerIdCard,
          signerMobile: this.signer.signerMobile
        }).then((res) => {
        this.loading=false
          if (res.code == 200) {   
            cookie.set('checkEntity', {entityCharacter: 'P', entityId: res.data.entityId})
            window.location.href = `${window.location.origin}/buyercenter?entityId=${res.data.entityId}`
          } else {
            this.$message.error(res.message)
          }
        }).catch(r=>{
          this.loading=false
          this.$message.error(r.message)
        })
    }

  }
}
</script>

<style lang="less" scoped>
.buyer {
   margin-bottom: 100px;
   max-width: 1440px;
   min-width: 990px; 
   @media screen and (max-width: 1440px){
   padding: 0 15px;
   }
  margin: 20px auto;
  .mainbody {

    height: 782px;
    background-color: #ffffff;
    .header-title {
      padding: 30px;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.847058823529412);
      font-weight: 650;
    }
    .header-text {
      padding: 0 0 0 46px;
      font-size: 16px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.647058823529412);
    }
    .basic-information {
      display: flex;
      justify-content: space-between;
      padding: 0 30px;
      .enterprise-left {
        font-size: 14px;
        font-weight: 400;
        width: 470px;
        padding: 60px 0 0 0;
        text-align: right;
        .buyer-input {
          width: 343px;
          @media screen and (max-width: 1200px){
           width: 220px;
         }
          height: 32px;
          border: 1px solid rgba(0, 0, 0, 0.247058823529412);
          background-color: rgba(245, 245, 245, 1);
          border-radius: 5px;
          padding: 0 0 0 10px;
        }
        .activate-input {
          background-color: #ffffff;
        }
        // .enterprise-information {
        // }
        .contract-information {
          padding: 20px 0 60px 0;
        }
        .information-title {
          text-align: left;
          font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑',
            sans-serif;
          padding: 0 0 20px 45px;
          font-size: 18px;
          font-weight: 700;
          color: rgba(0, 0, 0, 0.847058823529412);
        }
        p {
          margin-bottom: 20px;
        }
      }

      .enterprise-right {
        width: 630px;
        @media screen and (max-width: 1200px){
        transform: scale(.8);
         }
        padding: 65px 0 0 25px;
        .permit-logo {
          cursor: pointer;
          width: 550px;
          height: 230px;
          border: 1px dashed transparent;
          background: linear-gradient(white, white) padding-box,
            repeating-linear-gradient(
              -45deg,
              #1890ff 0,
              #ccc 0.3em,
              white 0,
              white 0.7em
            );
          display: flex;
          border-radius: 5px;
          justify-content: center;
          align-items: center;
          .ocr {
            width: 240px;
            height: 230px;
            text-align: center;
          }
          p {
            margin: 50px auto;
            width: 63px;
            height: 51px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .ocr-text {
            font-size: 14px;
            width: 240px;
          }
          .ocrimg {
            width: 240px;
            height: 230px;
            position: absolute;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      
    }
    .xy{
      width: 137px;
      margin: auto;
    }
    .next {
      width: 137px;
      height: 40px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 400;
      border-radius: 4px;
      background-color: rgba(24, 144, 255, 1);
      line-height: 40px;
      text-align: center;
      margin: 0 auto;
      color: #ffffff;
    }
  }
}
/deep/.ant-spin-dot-item{
    background-color: rgba(24, 144, 255, 1);
}
/deep/.ant-spin{
  font-size: 20px;
  font-weight: 500;
  color: #1890ff;
   margin-left: -25px;
  }
</style>