import request from '@/utils/request'


// 七牛云 图片上传
export function qiniu(params){
    return request({
        url:'/qiniu/token',
        method:'get',
        params
    })
}
// ocr 识别营业执照
export function ocr(params) {
    return request({
        url: '/trade/pur/ocr',    
        method: 'get',
        params   
    })
}

// 买家入驻
export function apply(data) {
    return request({
        url: '/trade/pur/apply',
        method: 'post',
        data
    })
}